<template>
  <div class="mobile-container">
    <div class="page-header">
      <div class="search-item">
        <div class="icon img-cover"><img src="../../assets/images/search-icon.png" /></div>
        <div class="input"><input v-model="searchKey" placeholder="输入要搜索的内容" type="search" @keyup.enter="handleSearch" /></div>
      </div>
    </div>
    <div class="right-popup-btn public-btn img-cover" @click="showAllClass = true">
      <img src="../../assets/images/menu-icon.png" />
    </div>
    <div class="tabs-item">
      <div class="class-item" v-for="(item, index) in classList" :key="index" :class="selectedClassData.data && selectedClassData.data.Id == item.Id ? 'active' : ''" @click="handleChangeClass(index, 0)">
        <div class="class">
          <div class="icon img-contain"><img :src="item.CategoryThumb" /></div>
          <div class="name">{{ item.Name }}</div>
        </div>
      </div>
    </div>
    <div class="page-content">
      <div class="content-item">
        <div class="left-item">
          <template v-if="selectedClassData.data.Childern && selectedClassData.data.Childern.length > 0">
            <div v-for="(item, index) in selectedClassData.data.Childern" :key="index" class="class-item" :class="selectedClassData.index1 == index ? 'active' : ''">
              <div class="class" :class="selectedClassData.index1 == index && selectedClassData.index2 == -1 ? 'active' : ''" @click="handleChangeClass(index, 1)">{{ item.Name }}</div>
              <div class="class-child">
                <div v-for="(child, childIndex) in item.Childern" :key="childIndex" class="class" :class="selectedClassData.index2 == childIndex ? 'active' : ''" @click="handleChangeClass(childIndex, 2)">{{ child.Name }}</div>
              </div>
            </div>
          </template>
        </div>
        <div class="right-item">
          <div class="bq-list-item">
            <div class="bq" :class="bqValue == '' ? 'active' : ''" @click="handleChangeBq('')">全部</div>
            <template v-if="(selectedClassData.index1 > -1 && selectedClassData.index2 == -1) && selectedClassData.data.Childern[selectedClassData.index1].Bq != ''">
              <div class="bq" v-for="(item, index) in setBqArr(selectedClassData.data.Childern[selectedClassData.index1].Bq)" :key="index" :class="bqValue == item ? 'active' : ''" @click="handleChangeBq(item)">{{ item }}</div>
            </template>
            <template v-if="(selectedClassData.index1 > -1 && selectedClassData.index2 > -1) && selectedClassData.data.Childern[selectedClassData.index1].Childern[selectedClassData.index2].Bq != ''">
              <div class="bq" v-for="(item, index) in setBqArr(selectedClassData.data.Childern[selectedClassData.index1].Childern[selectedClassData.index2].Bq)" :key="index" :class="bqValue == item ? 'active' : ''" @click="handleChangeBq(item)">{{ item }}</div>
            </template>
          </div>
          <div v-if="selectedClassData.index1 > -1 && selectedClassData.data.Childern[selectedClassData.index1].TanContent" class="sstz-item">
            <div class="icon img-cover"><img src="../../assets/images/news-1-icon.png" /></div>
            <div class="detail" @click="handleShowGg(selectedClassData.data.Childern[selectedClassData.index1].TanContent)">
              <van-notice-bar scrollable background="rgba(0, 0, 0, 0)" color="#ffa058">{{ selectedClassData.data.Childern[selectedClassData.index1].TanContent }}</van-notice-bar>
            </div>
            <div class="right img-cover"><img src="../../assets/images/right-1-icon.png" /></div>
          </div>
          <van-list v-model="loading" :finished="finish" @load="getGoodsList" loading-text="加载中..." finished-text="" error-text="加载失败" :immediate-check="false">
            <router-link :to="{path: '/goodsDetail', query: {id: item.Id}}" v-for="(item, index) in goodsList" :key="index" class="goods-item">
              <div class="img img-cover">
                <img v-if="item.GoodsThumb" :src="item.GoodsThumb" />
                <img v-else src="../../assets/images/no-image-icon.png" />
              </div>
              <div class="goods-info">
                <div class="name">{{ item.GoodsName }}</div>
                <div class="bq-item">
                  <div v-for="(bq, bqIndex) in item.Bq" :key="bqIndex" class="bq">{{ bq }}</div>
                </div>
                <div class="footer">
                  <div class="money">¥ <span>{{ item.GoodsPrice }}</span></div>
                  <div v-if="item.Lable" class="label">{{ item.Lable }}</div>
                </div>
              </div>
            </router-link>
          </van-list>
        </div>
      </div>
    </div>
    <van-popup v-model="showAllClass" position="right" :round="true">
      <div class="all-class-popup">
        <div class="title-item">
          <div class="title">全部分类</div>
          <div class="close img-cover" @click="showAllClass = false">
            <img src="../../assets/images/close-icon.png" />
          </div>
        </div>
        <div class="content-item">
          <div class="class-list">
            <div v-for="(item, index) in classList" :key="index" class="class" @click="handleChangeClass(index, 0);showAllClass = false;">
              <div class="icon img-contain"><img :src="item.CategoryThumb" /></div>
              <div class="name">{{ item.Name }}</div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
    <mobilePopupComponent v-if="showMobilePopup" ref="mobilePopup" @onSure="mobilePopupOnSure" />
    <mobile-tab-bar :tabBarKey="tabBarKey"></mobile-tab-bar>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import mobileTabBar from '@/components/mobileTabBar'
import mobilePopupComponent from '@/components/mobilePopupComponent'

export default {
  name: "Class",
  components: {
    mobileTabBar,
    mobilePopupComponent
  },
  data() {
    return {
      tabBarKey: 'class',
      showMobilePopup: false,
      queryId: '',
      classList: [],
      selectedClassData: {
        data: {},
        index1: -1,
        index2: -1
      },
      searchKey: '',
      bqValue: '',
      goodsName: '',
      goodsList: [],
      pageCount: 10,
      page: 1,
      finish: false,
      loading: false,
      showAllClass: false
    }
  },
  computed: {
    ...mapState(['userInfo', 'websiteInfo'])
  },
  watch: {
    searchKey(val) {
      if (val == '') {
        this.handleSearch()
      }
    }
  },
  mounted() {
    if (this.$route.query && this.$route.query.id) {
      this.queryId = this.$route.query.id
    }
    this.getClassList()
  },
  methods: {
    handleShowGg(data) {
      this.showMobilePopup = true
      this.$nextTick(() => {
        this.$refs.mobilePopup.open({
          title: '下单必看',
          tips: '',
          content: data,
          sureText: '我知道了',
          showCancel: false
        })
      })
    },
    mobilePopupOnSure() {
      this.showMobilePopup = false
    },
    setBqArr(str) {
      return str.split(',')
    },
    async getClassList() {
      var res = await this.$API.goodsCategoryList.post({
        IsAll: 1
      });
      const data = res.info
      if (data && data.length > 0) {
        const { queryId } = this
        if (queryId) {
          for (let index = 0; index < data.length; index++) {
            const item = data[index];
            if (item.Id == queryId) {
              const obj = {
                data: { ...item },
                index1: -1,
                index2: -1
              }
              if (item.Childern && item.Childern.length > 0) {
                obj.index1 = 0
              }
              this.selectedClassData = obj
              break
            }
          }
        } else {
          const obj = {
            data: { ...data[0] },
            index1: -1,
            index2: -1
          }
          if (data[0].Childern && data[0].Childern.length > 0) {
            obj.index1 = 0
          }
          this.selectedClassData = obj
        }
        this.classList = data
        this.getGoodsList()
        if (this.selectedClassData.data.Childern[this.selectedClassData.index1].IsTan === 1) {
          this.handleShowGg(this.selectedClassData.data.Childern[this.selectedClassData.index1].TanContent)
        }
      }
    },
    handleChangeBq(item) {
      this.bqValue = item
      this.goodsList = []
      this.page = 1
      this.finish = false
      this.loading = false
      this.getGoodsList()
    },
    handleChangeClass(index, level) {
      const { classList } = this
      if (level === 0) {
        const obj = {
          data: { ...classList[index] },
          index1: -1,
          index2: -1
        }
        if (classList[index].Childern && classList[index].Childern.length > 0) {
          obj.index1 = 0
        }
        this.selectedClassData = obj
      }
      if (level === 1) {
        this.selectedClassData.index1 = index
        this.selectedClassData.index2 = -1
        if (this.selectedClassData.data.Childern[this.selectedClassData.index1].IsTan === 1) {
          this.handleShowGg(this.selectedClassData.data.Childern[this.selectedClassData.index1].TanContent)
        }
      }
      if (level === 2) {
        this.selectedClassData.index2 = index
      }
      this.bqValue = ''
      this.goodsList = []
      this.page = 1
      this.finish = false
      this.loading = false
      this.getGoodsList()
    },
    handleSearch() {
      this.goodsName = this.searchKey
      this.goodsList = []
      this.page = 1
      this.finish = false
      this.loading = false
      this.getGoodsList()
    },
    async getGoodsList() {
      const { userInfo } = this
      if (userInfo && userInfo.Id && userInfo.UserIsGoods != 1) {
        this.finish = true
        this.loading = false
        return
      }
      const { pageCount, page, goodsList, selectedClassData, goodsName, bqValue, websiteInfo } = this
      const params = {
        PageCount: pageCount, 
        Page: page,
        AdminId: websiteInfo.Id
      }
      if (goodsName) {
        params.GoodsName = goodsName
      }
      if (bqValue) {
        params.Bq = bqValue
      }
      if (selectedClassData.data.Id) {
        params.CategoryId = selectedClassData.data.Id
      }
      if (selectedClassData.index1 > -1) {
        params.CategoryId2 = selectedClassData.data.Childern[selectedClassData.index1].Id
      }
      if (selectedClassData.index2 > -1) {
        params.CategoryId3 = selectedClassData.data.Childern[selectedClassData.index1].Childern[selectedClassData.index2].Id
      }
      var res = await this.$API.goodsList.post(params)
      if (res.info && res.info.length > 0) {
        for (let index = 0; index < res.info.length; index++) {
          const item = res.info[index]
          res.info[index].Bq = item.Bq ? item.Bq.split(',') : []
        }
      }
      if (page === 1) {
        this.goodsList = [...res.info]
      } else {
        this.goodsList = [
          ...goodsList,
          ...res.info
        ]
      }
      this.page = page + 1
      this.finish = res.info.length < pageCount
      this.loading = false
    },
    async handleCollection(item, index) {
      var res = await this.$API.addGoodsCollect.post({
        GoodsId: item.Id
      })
      if (res.error == 0) {
        this.goodsList[index].IsCollect = item.IsCollect > 0 ? 0 : 1
      } else {
        this.$dialog.alert({
          type: 'danger',
          title: '提示',
          message: res.info,
          confirmButtonText: '确定',
        })
      }
      return false
    }
  }
};
</script>

<style lang="less" scoped>
.mobile-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}
.page-header {
  padding: .24rem .32rem .64rem;
  background: linear-gradient( 135deg, #95cffc 0%, #58b7ff 100%);
  .search-item {
    width: 100%;
    height: .72rem;
    border-radius: .36rem;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .icon {
      margin: 0 .2rem;
      width: .32rem;
      height: .32rem;
    }
    .input {
      flex: 1;
      height: .4rem;
      font-size: .24rem;
      color: #333;
    }
  }
}
.right-popup-btn {
  cursor: pointer;
  width: .4rem;
  height: .4rem;
  padding: .2rem .08rem .2rem .12rem;
  position: fixed;
  z-index: 99;
  right: 50%;
  top: 1.58rem;
  margin-right: var(---max-width-50);
  border-radius: .24rem 0 0 .24rem;
}
.tabs-item {
  width: 100%;
  height: 1.7rem;
  box-sizing: border-box;
  border-radius: .4rem .4rem 0 0;
  margin-top: -.4rem;
  background-color: #fff;
  padding: .12rem .28rem 0;
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;
  .class-item {
    display: inline-block;
    margin: 0 .12rem;
    border-radius: .32rem .32rem 0 0;
    &.active {
      background: linear-gradient( 180deg, #cfeaff 0%, #f8f8f8 100%);
    }
    .class {
      cursor: pointer;
      width: 1.08rem;
      height: 1.32rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .icon {
      width: .72rem;
      height: .72rem;
    }
    .name {
      margin: .02rem .02rem 0;
      line-height: .34rem;
      text-align: center;
      color: #151d2b;
      font-size: .24rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.page-content {
  flex: 1;
  width: 100%;
  margin-top: -.26rem;
  padding-top: .12rem;
  background-color: #f8f8f8;
  border-radius: .24rem .24rem 0 0;
  overflow: hidden;
  .content-item {
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: .2rem .2rem 0 0;
    display: flex;
    justify-content: space-between;
    .left-item {
      width: 1.88rem;
      height: 100%;
      background-color: #f8f8f8;
      overflow-y: auto;
      .class-item {
        padding-left: .32rem;
        padding-right: .2rem;
        border-radius: .24rem 0 0 .24rem;
        position: relative;
        height: .8rem;
        overflow: hidden;
        &.active {
          height: auto;
          background-color: #fff;
          &::after {
            content: '';
            position: absolute;
            left: 0;
            top: .2rem;
            width: .08rem;
            height: .4rem;
            border-radius: 0 .06rem .06rem 0;
            background-color: #276eff;
          }
        }
        .class {
          cursor: pointer;
          height: .8rem;
          line-height: .8rem;
          font-size: .28rem;
          color: #242941;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          &.active {
            color: #276eff;
          }
        }
        .class-child {
          .class {
            color: #242941;
            padding-left: .12rem;
            border-top: .02rem solid #f8f8f8;
            &.active {
              color: #276eff;
            }
          }
        }
      }
    }
    .right-item {
      flex: 1;
      height: 100%;
      overflow-y: auto;
      .bq-list-item {
        white-space: nowrap;
        padding: .2rem .14rem 0;
        overflow-x: auto;
        .bq {
          cursor: pointer;
          display: inline-block;
          height: .52rem;
          line-height: .52rem;
          margin: 0 .1rem;
          padding: 0 .24rem;
          background-color: #f8f8f8;
          border-radius: .28rem;
          color: #242941;
          font-size: .24rem;
          border: .02rem solid transparent;
          &.active {
            color: #276eff;
            border-color: #276eff;
            border-radius: .28rem;
            background-color: rgba(19, 153, 255, .16);
          }
        }
      }
      .sstz-item {
        padding: .04rem .2rem .04rem .08rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .icon {
          width: .64rem;
          height: .64rem;
        }
        .detail {
          cursor: pointer;
          flex: 1;
          padding:  0 .16rem;
          /deep/.van-notice-bar {
            font-size: .24rem;
            padding: 0;
          }
        }
        .right {
          width: .24rem;
          height: .24rem;
        }
      }
      .goods-item {
        padding: .2rem .32rem .2rem .24rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .img {
          width: 1.44rem;
          height: 1.44rem;
          border-radius: .16rem;
          overflow: hidden;
        }
        .goods-info {
          width: 3.38rem;
          .name {
            width: 100%;
            height: .4rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: .28rem;
            color: #333;
            line-height: .4rem;
          }
          .bq-item {
            margin: .18rem 0 .2rem;
            height: .28rem;
            overflow: hidden;
            .bq {
              float: left;
              margin-right: .12rem;
              font-size: .2rem;
              color: #999;
              line-height: .28rem;
              padding: 0 .12rem;
              border-radius: .04rem;
              background-color: #f8f8f8;
            }
          }
          .footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .money {
              font-weight: bold;
              color: #ff2442;
              font-size: .2rem;
              span {
                font-size: .32rem;
              }
            }
            .label {
              height: .32rem;
              color: #fff;
              font-size: .2rem;
              line-height: .32rem;
              padding: 0 .12rem;
              border-radius: .04rem;
              background: linear-gradient( 315deg, #ffaa8a 0%, #ffde58 100%);
            }
          }
        }
      }
    }
  }
}
/deep/.van-popup--right {
  &.van-popup--round {
    border-radius: .4rem 0 0 .4rem;
  }
}
.all-class-popup {
  width: 5.2rem;
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .title-item {
    padding: 1.4rem .4rem .24rem .32rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-size: .3rem;
      color: #030304;
      font-weight: bold;
    }
    .close {
      cursor: pointer;
      width: .24rem;
      height: .24rem;
      padding: .12rem;
    }
  }
  .content-item {
    flex: 1;
  }
  .class-list {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    .class {
      cursor: pointer;
      width: 33%;
      height: 1.58rem;
      float: left;
      padding: .24rem;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .icon {
        width: .72rem;
        height: .72rem;
        border-radius: .12rem;
        overflow: hidden;
      }
      .name {
        margin-top: .04rem;
        text-align: center;
        line-height: .34rem;
        color: #333;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>
